@keyframes colorLoop4 {
  0% {
    background-color: map-get($map: $colors, $key: 'specialDetail2');
  }

  50% {
    background-color: map-get($map: $colors, $key: 'specialDetail');
  }

  100% {
    background-color: map-get($map: $colors, $key: 'specialDetail2');
  }
}

select {
  color: map-get($map: $colors, $key: 'mainWhite');
  background-color: map-get($map: $colors, $key: 'mainDark');
  padding: 5px 10px;
  font-size: 1.2rem;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  outline: none;

}

.PartOfFilters {
  display: flex;
  gap: 10px;
  min-height: 35px;
}

.SearchCont {
  display: flex;
  justify-content: space-between
}

.SearchByQ {
  padding: 5px 10px;
  border: none;
  border: 0.3rem solid map-get($map: $colors, $key: 'mainDark');
  border-radius: 5px;
  font-size: 1.2rem;
  outline: none;

  &:focus {
    animation-name: colorLoop3;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    // border: 0.3rem solid map-get($map:$colors, $key: 'specialDetail');
  }
}

.SearchButton {
  background: none;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 0.3rem solid map-get($map: $colors, $key: 'mainDark');
  transition: 0.2s;

  & img {
    width: 15px;
    height: 15px;
  }

  &:hover {
    cursor: pointer;
    border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail');
  }
}

.AllNewsCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0px;

}

.NewCardCont {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  background-color: map-get($map: $colors, $key: 'mainDark');
  border-radius: 10px;
  padding: 15px;
  color: map-get($map: $colors, $key: 'mainWhite');

}

.firstRowNew {
  display: flex;
  height: auto;
  gap: 15px;

  & img {
    width: 60%;
    object-fit: cover;
    animation-name: colorLoop4;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    background-color: map-get($map: $colors, $key: 'specialDetail');
    border-radius: 5px;
  }

  & div {
    width: 100%;
  }
}

.NewTitleCard {
  margin-bottom: 15px;
  font-size: 1.3rem;
  font-weight: 200;
}

.NewDateCard {
  font-size: 1.2rem;
  font-weight: 400;
}

.ColorChangeButton {
  background-color: map-get($map: $colors, $key: 'mainWhite');
  color: map-get($map: $colors, $key: 'mainDark');
  padding: 5px 10px;
  font-weight: 600;

}

.ButtonContCard {
  display: flex;
  justify-content: space-between;
}

.MarkButton {
  background: none;
  border: none;
  height: 27px;
  width: 27px;
  cursor: pointer;

  & img {
    width: 100%;
    object-fit: cover;
  }
}

.mini-loading {
  margin-left: 10px;
  height: 17px;
  width: 20px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  border: 4px solid #add8e6;
  border-color: map-get($map: $colors, $key: 'mainDark') map-get($map: $colors, $key: 'mainWhite');
  box-sizing: border-box;
  animation: loading 0.7s linear infinite;
}

@-moz-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Progresbar {
  width: 100%;
  height: 8px;
  background-color: map-get($map: $colors, $key: 'mainWhite');
  border-radius: 20px;

  & div {
    height: 100%;
    border-radius: 10px;
    background-color: map-get($map: $colors, $key: 'specialDetail');
  }
}

.ClasificationText {
  font-size: 1.2rem;
  font-weight: 500;
}

.BarsCont {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & div {
    display: flex;
    flex-direction: column;
    gap: 3px
  }
}

.ClasificationText1 {
  @extend .ClasificationText;
  font-weight: 600;
  font-size: 1.2rem;
}


@keyframes colorLoopLoad {
  0% {
    background-color: rgb(190, 190, 190);
    color: rgb(190, 190, 190);
  }

  50% {
    background-color: rgb(223, 223, 223);
    color: rgb(223, 223, 223);
  }

  100% {
    background-color: rgb(190, 190, 190);
    color: rgb(190, 190, 190);
  }
}

.ImgLoading {
  border-radius: 5px;
  animation: colorLoopLoad 3s infinite ease;
}

.bgLoad {
  background-color: rgb(236, 236, 236);
}

.TextLoad {
  animation: colorLoopLoad 3s infinite ease;
  border-radius: 20px;

}

.TextLoadDate {
  width: 120px;
}

.NewDateCard2 {
  font-size: 1.3rem;
  font-weight: 500;
}

.DateAndSource {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ContWithTheQuit {
  animation: fadeinbg 0.2s ease;
  top: 0px;
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: map-get($map: $colors, $key: 'mainWhiteBack');
  display: flex;
}

@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
  }

  to {
    opacity: 1;

  }
}

@keyframes fadeinbg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;

  }
}

.FullContNew {
  animation: fadein 0.2s ease;
  top: 0px;
  z-index: 5;
  width: 960px;
  background-color: map-get($map: $colors, $key: 'mainDark');
  padding: 20px;
  border-radius: 15px;
  display: flex;
  gap: 20px;
  max-height: 80%;
  overflow: auto;

  & p {
    min-width: 50%;
    overflow: auto;
    max-height: 600px;
    font-size: 1rem;
    line-height: 1.8;
    max-width: 70%;
    color: map-get($map: $colors, $key: 'mainWhite');
  }

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ImgFullNew {
  object-fit: cover;
  max-height: 50%;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 1050px) {
  .FullContNew {
    width: 85vw;
  }
}

@media (max-width: 750px) {
  .FullContNew {
    flex-direction: column;
    max-height: 80vh;
    padding: 10px;

    & p {
      max-height: none;
      max-width: none;
      overflow: visible;
    }
  }
}

.TitleInFullNew {
  color: map-get($map: $colors, $key: 'mainWhite');
  font-size: 1.5rem;
}

.LinkButton {
  @extend .GitHubButton;

  background-color: map-get($map: $colors, $key: 'mainWhite');
  color: map-get($map: $colors, $key: 'mainDark');
  width: fit-content;
  font-weight: 600;
  border-radius: 10px;

}

.Part1FullNew {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.MiniInfoFullBox {
  color: map-get($map: $colors, $key: 'mainWhite');
  font-size: 1.3rem;
  font-weight: 400;
}

.MiniInfoFullBox2 {
  margin-top: 10px;
  @extend .MiniInfoFullBox;
  font-weight: 600;
}

@media (max-width: 840px) {
  .SearchCont {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .PartOfFilters {
    justify-content: space-between;
  }

  .PartOfFiltersWrap {
    flex-wrap: wrap;
  }

  .SearchByQ {
    width: 100%;
  }
}

.ErrorContainer {
  flex-wrap: wrap;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;

  & .ilustration {
    width: 300px;
  }

  & p {
    font-weight: 600;
    font-size: 2rem;
  }

  & div {
    // width: 250px;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

}