@keyframes colorLoop {
    0% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail');
    }

    50% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail2');
    }

    100% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail');
    }
}

@keyframes colorLoop2 {
    0% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail2');
    }

    50% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail');
    }

    100% {
        border: 0.5rem solid map-get($map: $colors, $key: 'specialDetail2');
    }
}

@keyframes colorLoop3 {
    0% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail2');
    }

    50% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail');
    }

    100% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail2');
    }
}

@keyframes colorLoopMini {
    0% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail2');
    }

    50% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail');
    }

    100% {
        border: 0.3rem solid map-get($map: $colors, $key: 'specialDetail2');
    }
}

.navbar {
    z-index: 1;
    // position: sticky;
    // top: 40px;
    // position: relative;
    display: flex;
    // width: 100%;
    max-width: 1000px;
    margin: auto;
    // position: fixed;
    // padding-top: 40px;
    // margin-top: 10px;
    // top: 30px;
    // top: 40px;
    // left: 50%;
    margin: 40px auto;
    // transform: translateX(-50%);
    justify-content: space-between;
    align-items: center;
    padding: 0px 2rem;
}

.NavTitle {
    font-size: 3rem;
    font-weight: 600;
    opacity: 0.8;
}

.FinderBorder {
    opacity: 100%;
    margin-left: 10px;
    // border: 0.6rem solid map-get($map:$colors, $key: 'specialDetail');
    animation-name: colorLoop;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    padding: 0.01rem 1rem;
    border-radius: 10px;
}

.AppTitleUni {
    text-decoration: none;
    color: map-get($map: $colors, $key: 'mainDark');
    display: flex;
    align-items: center
}

.AppTitleUni2 {
    display: flex;
    gap: 10px;
}

.MiniDescrip {
    margin-left: 6px;
    font-weight: 600;
    font-size: 1.3rem;
}

.GitHubButton {
    gap: 10px;
    align-items: center;
    display: flex;
    padding: 8px 10px;
    border: none;
    color: map-get($map: $colors, $key: 'mainWhite');
    text-decoration: none;
    background-color: map-get($map: $colors, $key: 'mainDark');
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    & img {
        // margin-right: 10px;
        width: 17px;
    }
}

.BackButtonToClose {
    top: 0;
    left: 0;
    // transform: translate(-100%, 0%);
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.ButtonIcon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;

    & img {
        width: 19px;
    }
}

.BurgerMenu {
    border: 0.3rem solid map-get($map: $colors, $key: 'mainDark');
    padding: 5px;
    border-radius: 5px;
}

.CloseButton {
    // margin: -8px;
    width: fit-content;

    & img {
        width: 40px;
    }
}

.SideBar {
    z-index: 2;
    background-color: map-get($map: $colors, $key: 'mainDark');
    position: fixed;
    height: 90vh;
    padding: 5vh;
    top: 0px;
    right: 0%;
    transition: 0.6s ease;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.SideBarHide {
    transition: 0.6s ease-in;
    right: -100%;
}

.NavMoveItem {
    background: none;
    border: none;
    text-decoration: none;
    color: map-get($map: $colors, $key: 'mainWhite');
    font-size: 1.4rem;
    border-radius: 7px;
    padding: 0px 15px 0px 15px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    & img {
        width: 15px;
        height: 15x;
    }
}

.ListNavItem {
    // margin: 25px 0px;
    width: fit-content;
}

.ListNavCont {
    // margin-top: 70px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.NavOptionSelected {
    padding: 7px 15px 7px 15px;
    border: none;
    background-color: map-get($map: $colors, $key: 'mainWhite');
    color: map-get($map: $colors, $key: 'mainDark');

    &:hover {
        cursor: default;
        border: none;
    }
}

.borderDetail {
    padding: 5px 0px;
    border: 3px solid map-get($map: $colors, $key: 'specialDetail');
    width: 0%;
    border-radius: 7px;
    transition: 0.5s ease;

    &:hover {
        border: 3px solid map-get($map: $colors, $key: 'specialDetail2');
        width: 100%;
    }
}

.MiniIconInformation {
    transition: 0.3s;
    opacity: 0.6;

    & img {
        width: 25px;
    }

    &:hover {
        opacity: 1;
    }
}

.MiniIconCont {
    display: flex;
    justify-content: space-between
}

.FullContSetting {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    position: fixed;
    width: 200px;
    // height: 200px;
    border-radius: 15px;
    background-color: map-get($map: $colors, $key: 'mainDark');
    padding: 10px;

    & .GitHubButton {
        margin: 10px;
    }
}

.UserLogoSettings {
    width: 30px;
    margin: 10px;
    border-radius: 100%;
}

.NameAndLogo {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;

    & h3 {
        margin-left: 10px;
        color: map-get($map: $colors, $key: 'mainWhite');
        font-size: 1.5rem;
    }
}

.LogoNavMobil {
    display: none;
}

@media (max-width: 750px) {
    .AppTitleUni {

        & .NavTitle,
        .MiniDescrip {
            display: none !important;
        }
    }

    .LogoNavMobil {
        display: block;
        border-radius: 5px;
        padding: 5px;
        width: 20px;
        height: 20px;
        animation-name: colorLoopMini;
        animation-duration: 15s;
        animation-iteration-count: infinite;
    }

    #GithubButton {
        display: none;
    }
}

.nonnHover {
    &:hover {
        cursor:auto;
    }
}
ul{
    list-style: none;
}
.Rights{
    font-weight: 200;
    font-size: 1rem;
    color: map-get($map:$colors, $key: 'mainWhite');
}
footer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    // align-items: stretch
}

.ButtonCloseBox{
    background: none;
    border: none;
    height: 50px;
    & img{
        width: 50px;
        filter: invert(0.85);
    }
    margin: 20px;
    &:hover{
        cursor: pointer;
    }
}