@keyframes callTheAtention {
    0% {
        margin-left: 0px;
        margin-right: 0px;
    }

    5% {
        margin-left: 3px;
        margin-right: 0px;
    }

    7% {
        margin-left: 0px;
        margin-right: 0px;
    }

    10% {
        margin-left: 0px;
        margin-right: 3px;
    }

    15% {
        margin-left: 0px;
        margin-right: 0px;
    }

    100% {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.PercentageItem {
    width: 80px;
    height: 80px;
    border: 0.5rem solid map-get($map: $colors, $key: 'mainDark');
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($map: $colors, $key: 'mainWhite');
    font-size: 1.5rem;
    text-align: center;
    transition: 0.3s ease;

    & img {
        width: 50%;
    }
}

.srcores {
    & span {
        margin-top: 0px;
        transition: 0.2s ease;
        opacity: 1;
        animation: callTheAtention 4s ease infinite;

    }

    &:hover span {
        margin-top: 20px;
        transition: 0.2s ease;
        opacity: 0;
        position: absolute;
    }

    &:hover:before {
        transition: 0.2s ease;
        opacity: 1;
        margin-bottom: 0px;
    }

    &:before {
        transition: 0.2s ease;
        margin-bottom: 20px;
        opacity: 0;
        // max-width: 5%;
        position: absolute;
        content: "F1 \a score";
        white-space: pre;

    }
}

.srcore2 {
    & span {
        animation-delay: 0.7s;
        margin-top: 0px;
        transition: 0.2s ease;
        opacity: 1;
        animation-delay: 10s;
        animation: callTheAtention 4s ease infinite;
    }

    &:hover span {
        margin-top: 20px;
        transition: 0.2s ease;
        opacity: 0;
        position: absolute;
    }

    &:hover:before {
        transition: 0.2s ease;
        opacity: 1;
        margin-bottom: 0px;
    }

    &:before {
        transition: 0.2s ease;
        margin-bottom: 20px;
        opacity: 0;
        position: absolute;
        content: "Recall \a score";
        white-space: pre;
    }
}

.img1 {
    background-image: linear-gradient(map-get($map: $colors, $key: 'secondDark'), map-get($map: $colors, $key: 'secondDark')), url("../img/foto1.jpg");
    background-size: cover;
    // backdrop-filter:  blur(20px);
}

.img2 {
    background-image: linear-gradient(map-get($map: $colors, $key: 'secondDark'), map-get($map: $colors, $key: 'secondDark')), url("../img/foto2.jpg");
    background-size: cover;
}

.MagicalBorder {
    animation-name: colorLoop2;
    animation-duration: 15s;
    animation-iteration-count: infinite;
}

.squarsCont {
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
    justify-content: space-between
}

.SquarsPlusText {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & p {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        background-color: map-get($map: $colors, $key: 'mainWhite');
        border: 0.5rem solid map-get($map: $colors, $key: 'mainDark');
        border-radius: 10px;
        padding: 20px;
        font-size: 1rem;
        // font-weight: 500;
    }
}

.MainPhoto {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: 100%;
    object-fit: cover;
    border: 0.5rem solid map-get($map: $colors, $key: 'mainDark');
    border-radius: 10px;
}

.HorizontalContLand {
    gap: 30px;
    display: flex;
    justify-content: space-between;
}

.CommentsCont {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    gap: 0.5rem;
    background-color: map-get($map: $colors, $key: 'mainDark');
    padding: 0.5rem;
    border-radius: 10px;
    margin: 30px 0px;
    // margin-top: 30px;

}

.CartComent {
    background-color: map-get($map: $colors, $key: 'mainWhite');
    padding: 15px;
    // max-width: 225px;
    border-radius: 5px;

    & div {
        display: flex;
        align-items: center;

        & h4 {
            margin-left: 20px;
            font-size: 1.4rem;
        }

        & img {
            object-fit: cover;
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
    }
}

.commentP {
    margin-top: 10px;
}

.Fil {
    background-color: map-get($map: $colors, $key: 'mainDark');

    & img {
        width: 45px;
    }
}

.errorMsg {
    border: .5rem solid map-get($map: $colors, $key: 'mainDark');
    transition: 0.5s;

    &:hover {
        @extend .MagicalBorder;
        cursor: pointer;
    }

    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    display: flex;

    & img {
        height: 40pxs;
        width: 40px;
    }

    & h5 {
        font-size: 1.2rem;
        font-weight: 800;
    }
}

.MainBanner {
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    grid-gap: 30px;
    align-items: center;

    // gap: 0px;
    & h1 {
        font-weight: 700;
        font-size: 3rem;
        max-width: 500px;
    }

}

.BannerPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 800px) {

    .HorizontalContLand {
        flex-direction: column;
        gap: 20px;

        & img {
            max-width: 100%;
            max-height: 200px;
        }

        & div {
            max-width: 100%;
        }
    }

    .MainPhoto {
        display: none;
    }

    .CommentsCont {
        gap: 0.5rem;
    }


}

@media (max-width: 600px) {
    .MainBanner {
        grid-template-columns: 1fr;

        & .BannerPhoto {
            display: none;
        }
    }
}

@media (max-width: 449px) {
    .HorizontalContLand {
        flex-direction: column;

        & img {
            max-width: 100%;
            max-height: 200px;
        }

        & div {
            max-width: 100%;
        }
    }
}



.GitHubButton:hover .MoveOnHover {
    transition: 0.3s ease;
    rotate: 360deg;
}

.GitHubButton:hover .MoveOnHover1 {
    transition: 0.3s ease;
    transform: translate(3px);
}

.GitHubButton .MoveOnHover1 {
    transition: 0.3s ease;
}

.GitHubButton .MoveOnHover {
    transition: 0.3s ease;
    // transform: translate(5px);
}