@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$colors:(
    'mainDark': #262626,
    'secondDark': #2626269f,
    'dots': #26262634,
    'specialDetail': #FF6666,
    'specialDetailBar': #ff6666a9,
    'specialDetail2': #ED81FF,
    'mainWhite': #ffff,
    'mainWhiteBack': #ffffff75,
    'selected': #FF6666,
    'bad': #ff2b2b,
    'good': #24FF00,
    'normal': #e872ff
);
*{
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
};
html{
    background-image: radial-gradient(map-get($map:$colors, $key: 'dots') 1.2px, transparent 0);
    background-size: 25px 25px;
    font-size: 80%;
    background-attachment: fixed
}
.FullContainer{
    // background-color: #262626;
    position: relative;
    max-width: 1000px;
    margin: auto;
    padding: 0px 2rem;
}
*::-webkit-scrollbar{
    width: 5px;
    border-radius: 20px;
    background-color:  map-get($map:$colors, $key: 'mainDark');
  }
*::-webkit-scrollbar-thumb {
    background-color: map-get($map:$colors, $key: 'specialDetailBar');
    border-radius: 5px;
  }
button:disabled{
    cursor:default;
}
  